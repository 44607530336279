// extracted by mini-css-extract-plugin
export var aboutBannerImg = "about-module--aboutBannerImg--6159e";
export var aimContainer = "about-module--aimContainer--4df29";
export var aimElement = "about-module--aimElement--d5c63";
export var bannerOverlay = "about-module--bannerOverlay--6bb97";
export var blogHeader = "about-module--blogHeader--bbebb";
export var clientImg = "about-module--clientImg--2d509";
export var clientWrapper = "about-module--clientWrapper--8ddcc";
export var presentationVideo = "about-module--presentationVideo--258ec";
export var reasonContainer = "about-module--reasonContainer--4c5fb";
export var reasonHeadingCont = "about-module--reasonHeadingCont--8ee0b";
export var reasonImg = "about-module--reasonImg--76a24";