import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import ScrollAnimation from "react-animate-on-scroll"
import SEO from "../components/seo"
import * as styles from "../styles/pages/about.module.css"
import { Carousel } from "react-bootstrap"
import { useStaticQuery } from "gatsby"
import "animate.css/animate.compat.css"
import "../styles/main.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"
import presentation from "../images/presentation.mp4"
import "swiper/css/swiper.css"

const Reason = props => {
  const { title, descrip } = props

  return (
    <div className={styles.reasonContainer}>
      <h3>{title}</h3>
      <p>{descrip}</p>
    </div>
  )
}

export default function About() {
  const data = useStaticQuery(graphql`
    query AboutPage {
      prismicAboutpage {
        data {
          about_banner_img {
            banner_img {
              fluid {
                src
                aspectRatio
                base64
                sizes
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
      prismicAboutpageBodyService {
        items {
          service_body {
            text
          }
          service_div_class {
            text
          }
          service_header {
            text
          }
          service_image {
            fluid {
              src
              aspectRatio
              base64
              sizes
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
      prismicAboutpageBodyClients {
        primary {
          client_title {
            text
          }
        }
        items {
          client_img {
            fluid {
              src
            }
            alt
          }
        }
      }
      allPrismicPost {
        edges {
          node {
            data {
              banner_image {
                fluid {
                  src
                  aspectRatio
                  base64
                  sizes
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
              blog_title {
                text
              }
            }
            uid
          }
        }
      }
    }
  `)

  const animation = {
    animateIn: "backInUp",
    animateOnce: true,
  }

  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  }

  return (
    <Layout>
      <SEO title="About" />
      <Carousel controls={false} interval={10000}>
        {data.prismicAboutpage.data.about_banner_img.map((node, i) => {
          return (
            <Carousel.Item key={i}>
              <div
                style={{
                  position: "relative",
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className={styles.bannerOverlay}></div>
                <Img
                  className={styles.aboutBannerImg + " d-block w-100"}
                  fluid={{
                    ...node.banner_img.fluid,
                    sizes: "",
                  }}
                  alt="about banner"
                />
              </div>
            </Carousel.Item>
          )
        })}
      </Carousel>
      <div style={{ background: "rgb(224, 224, 219)" }}>
        <div className={styles.aimContainer}>
          <div>
            <div className={styles.aimElement}>
              <ScrollAnimation {...animation}>
                <h3>Our Vision</h3>
                <p>
                  Provide affordable Software Solutions for Global Businesses.
                </p>
              </ScrollAnimation>
            </div>

            <div className={styles.aimElement}>
              <ScrollAnimation {...animation}>
                <h3>Our Mission</h3>
                <p>
                  Enable the world's businesses to be more productive and
                  successful.
                </p>
              </ScrollAnimation>
            </div>
          </div>
          <div>
            <ScrollAnimation {...animation}>
              <div className={styles.aimElement}>
                <h3>Our Operating Principles</h3>
                <p>
                  "A customer is the most important visitor on our premises. He
                  is not dependent on us. We are dependent on him. He is not an
                  interruption in our work. He is the purpose of it. He is not
                  an outsider in our business. He is part of it. We are not
                  doing him a favor by serving him. He is doing us a favor by
                  giving us an opportunity to do so." - Mahatma Gandhi
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div>
        <ScrollAnimation {...animation}>
          <div className={styles.reasonHeadingCont}>
            <h3>Our Values</h3>
            <p>Here's why we are outstanding</p>
          </div>
        </ScrollAnimation>
      </div>

      <div className={styles.reasonWrapper}>
        {data.prismicAboutpageBodyService.items.map((data, i) => {
          return (
            <ScrollAnimation key={i} {...animation}>
              <div className={data.service_div_class.text}>
                <div className={styles.reasonImg}>
                  <Img fluid={{ ...data.service_image.fluid, sizes: "" }} />
                </div>
                <div className={styles.reasonWrapper}>
                  <Reason
                    title={data.service_header.text}
                    descrip={data.service_body.text}
                  />
                </div>
              </div>
            </ScrollAnimation>
          )
        })}
      </div>

      {/* Code for client logos */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <ScrollAnimation {...animation}>
          <h1>{data.prismicAboutpageBodyClients.primary.client_title.text}</h1>
        </ScrollAnimation>
      </div>

      <div className={styles.clientWrapper}>
        {data.prismicAboutpageBodyClients.items.map((node, i) => {
          return (
            <div key={i}>
              <ScrollAnimation {...animation}>
                <img
                  className={styles.clientImg}
                  src={node.client_img.fluid.src}
                  alt={node.client_img.alt}
                />
              </ScrollAnimation>
            </div>
          )
        })}
      </div> */}

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "125px",
          }}
        >
          <h1>Our blog</h1>
        </div>
        <Swiper {...params}>
          {data.allPrismicPost.edges.map((node, i) => {
            let temp = node
            return (
              <div>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  to={`/blog/${temp.node.uid}`}
                  key={i}
                >
                  <div
                    style={{
                      maxWidth: "920px",
                      margin: "0 auto",
                    }}
                  >
                    <header className={styles.blogHeader}>
                      <h5 style={{ margin: "1em", height: "50px" }}>
                        {temp.node.data.blog_title.text}
                      </h5>
                    </header>
                    <Img
                      fluid={{
                        ...temp.node.data.banner_image.fluid,
                        sizes: "",
                      }}
                      style={{ margin: "3em" }}
                    />
                  </div>
                </Link>
              </div>
            )
          })}
        </Swiper>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "50px 0px",
        }}
      >
        <video className={styles.presentationVideo} muted controls>
          <source src={presentation} type="video/mp4" />
        </video>
      </div>
    </Layout>
  )
}
